<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app',
        data() {
            return {}
        }
    }
</script>

<style lang="less">
    #app{
        position: relative;
    }
    * {
        padding: 0;
        margin: 0;
    }

    body {
        font-size: 14pt;
        line-height: 1.75em;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    @media screen and (max-width:1200px) {
        body{
            font-size:10pt
        }
    }


    *, *:before, *:after {
        box-sizing: inherit;
    }
    #leimu{
        display: none !important;
    }

    .red {
        background: #DD3C3C;
        &:hover{
            background: red;
        }
    }

    .orange {
        background: #F27E31;
        &:hover{
            background: orange;
        }
    }

    .yellow {
        background: #FAC21F;
        &:hover{
            background: yellow;
        }
    }

    .olive {
        background: #BBCF2D;
        &:hover{
            background: olive;
        }
    }

    .green {
        background: #36BF56;
        &:hover{
            background: green;
        }
    }

    .teal {
        background: #18BBB3;
        &:hover{
            background: teal;
        }
    }

    .blue {
        background: #368FD3;
        &:hover{
            background: blue;
        }
    }

    .violet {
        background: #7248CD;
        &:hover{
            background: violet;
        }
    }

    .purple {
        background: #AB46CC;
        &:hover{
            background: purple;
        }
    }

    .pink {
        background: #E14BA0;
        &:hover{
            background: pink;
        }
    }

    .brown {
        background: #AC7551;
        &:hover{
            background: brown;
        }
    }

    .grey {
        background: #828282;
        &:hover{
            background: grey;
        }
    }

    .black {
        background: #303132;
        &:hover{
            background: black;
        }
    }

</style>
