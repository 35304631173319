import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/404',
        component: () => import('../views/404'),
        meta: {title: '404 NOT FOUND'},
        hidden: true
    },
    {
        path: '/',
        component: () => import('../views/Index'),
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('../views/home/Home'),
                meta: {
                    title: '首页'
                }
            },
            {
                path: '/television',
                name: 'television',
                component: () => import('../views/television/Television'),
                meta: {title: '追剧'}
            },
            {
                path: '/blog/:id',
                name: 'blog',
                component: () => import('../views/blog/Blog'),
                meta: {
                    title: '博客'
                }
            },
            {
                path: '/tag/:id',
                name: 'tag',
                component: () => import('../views/tag/Tag'),
                meta: {
                    title: '标签'
                }
            },
            {
                path: '/category/:categoryId',
                name: 'category',
                component: () => import('../views/category/Category'),
                meta: {
                    title: '分类'
                }
            },
            {
                path: '/about',
                name: 'about',
                component: () => import('../views/about/About'),
                meta: {
                    title: '关于'
                }
            },

        ]
    },
    {path: '*', redirect: '/404', hidden: true}
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    // 切换路由后滚动条置顶
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

// 挂载前置路由守卫
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // next必须调用从而进行下一步，否则会拦截路由跳转
    next()
})

export default router
